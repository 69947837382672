import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"

import {
	mapGetters
} from "vuex"
import { categoryList, goodsList, goodsAttrDetail, addCollect, deleteCollect, addCart, deleteCart } from "@/api/resource.js"

export default {
	name: "index",
	data: () => {
		return {
			 current_mode:1,
			 current_category:0,
			 category_id:'',
			 show_style :1,
			 goods_category:{},
			 all_category:[],
			 goods_list:[],
			 order:'modify_time',
			 sort:'desc',
			 goods_loading:true,
			 attr_list:[],
			 toUid:'',
		}
	},
	watch: {
		
	},
	created() {
		this.getCategoryList();
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll);
		if(localStorage.getItem('isKefuIndex') && this.token){
			this.toUid = 'member_' + localStorage.getItem('isKefuIndex');
			localStorage.removeItem('isKefuIndex');
			this.$refs.servicerMessage.show();
		}
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount","city","token"]),
	},
	methods: {
		evaluateGoods(goodsInfo,mode){
			if(this.current_mode==1){
				this.$router.pushToTab('/resource-'+goodsInfo.goods_id+'?status='+mode)
			}else{
				this.$router.pushToTab('/demand-'+goodsInfo.goods_id+'?status='+mode)
			}
		},
		toMemberInfo(id){
			this.$router.push('/personal-'+id);
		},
		toViewChat(id){
			if(!this.token){
				localStorage.setItem("isKefuIndex",id);
				this.$message.warning('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/"}});
				},800)
				return;
			}
			this.toUid = 'member_'+id;
			this.$refs.servicerMessage.show();
		},
		purchaseGoods(info){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/"}});
				},800)
				return;
			}
			if(info.is_cart){
				deleteCart({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_cart = 0;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}else{
				addCart({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_cart = 1;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}
		},
		collectGoods(info){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/"}});
				},800)
				return;
			}
			if(info.is_collect){
				deleteCollect({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_collect = 0;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}else{
				addCollect({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_collect = 1;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}
			
		},
		toGoodsDetail(goodsId){
			if(this.current_mode==1){
				this.$router.pushToTab('/resource-'+goodsId)
			}else{
				this.$router.pushToTab('/demand-'+goodsId)
			}
		},
		toGoodsCategory(id){
			this.$router.push('/list?category_id='+id+'&mode='+this.current_mode);
		},
		getGoodsAttrDetail(id){
			goodsAttrDetail({
				class_id : id
			})
			.then(res=>{
				if(res.code>=0){
					this.attr_list = this.$util.deepClone(res.data.attribute_list);
					var excel_header = [];
					this.attr_list.forEach((aItem,aIndex)=>{
						if(aItem.excel_show){
							excel_header.push(aItem)
						}
					});
					if(this.goods_list.length){
						var ids = [];
						excel_header.forEach(eItem=>{
							ids.push(Number(eItem.attr_id));
						})
						this.goods_list.forEach((gItem,gIndex)=>{
							this.$set(gItem,'excel_show',[]);
							excel_header.forEach((aItem,aIndex)=>{//表头循环
								var all_ids = [];
								gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
									all_ids.push(Number(pItem.attr_id))
								})
								if(gItem.goods_attr_parse){
									gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
										var id =[];
										gItem.excel_show.forEach(eItem=>{
											id.push(Number(eItem.attr_id));
										})
										if(pItem.attr_id == aItem.attr_id){
											if(ids.indexOf(Number(pItem.attr_id))!=-1){
												if(id.indexOf(Number(pItem.attr_id))==-1){
													gItem.excel_show.push(pItem);
												}
											}
										}
									})
									if(all_ids.indexOf(Number(aItem.attr_id))==-1){
										gItem.excel_show.push({attr_value_name:'无'});
									}
								}
							})
						})
					}
				}
			})
		},
		refresh(order){
			if(this.order == order){
				this.sort = this.sort=='desc'?'asc':'desc';
			}else{
				this.order = order;
				this.sort = 'desc';
			}
			this.goods_loading = true;
			this.getGoodsList();
		},
		getGoodsList(){
			goodsList({
				goods_class:this.current_mode==1?5:6,
				page:1,
				page_size:40,
				order:this.order,
				sort:this.sort,
				category_id:this.category_id
			})
			.then(res=>{
				if(res.code>=0){
					this.goods_list = res.data.list;
					this.goods_list.forEach(item=>{
						if(item.goods_attr_format){
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						}
					})
					if(this.attr_list.length){
						var excel_header = [];
						this.attr_list.forEach((aItem,aIndex)=>{
							if(aItem.excel_show){
								excel_header.push(aItem)
							}
						});
						var ids = [];
						excel_header.forEach(eItem=>{
							ids.push(Number(eItem.attr_id));
						})
						this.goods_list.forEach((gItem,gIndex)=>{
							this.$set(gItem,'excel_show',[]);
							excel_header.forEach((aItem,aIndex)=>{//表头循环
								var all_ids = [];
								gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
									all_ids.push(Number(pItem.attr_id))
								})
								if(gItem.goods_attr_parse){
									gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
										var id =[];
										gItem.excel_show.forEach(eItem=>{
											id.push(Number(eItem.attr_id));
										})
										if(pItem.attr_id == aItem.attr_id){
											if(ids.indexOf(Number(pItem.attr_id))!=-1){
												if(id.indexOf(Number(pItem.attr_id))==-1){
													gItem.excel_show.push(pItem);
												}
											}
										}
									})
									if(all_ids.indexOf(Number(aItem.attr_id))==-1){
										gItem.excel_show.push({attr_value_name:'无'});
									}
								}
								
							})
						})
					}
					this.goods_loading = false;
				}
			})
		},
		getCategoryList(){
			categoryList({
				goods_type:this.current_mode==1?'resource':'demand'
			})
			.then(res=>{
				if(res.code>=0){
					this.all_category = [{'category_id':'','category_name':'推荐'}]
					this.all_category.push(...this.$util.deepClone(res.data));
					this.category_id = this.all_category[0].category_id;
					this.current_category = 0;
					this.goods_category = {
						'transfer':[],
						'service':[],
					};
					res.data.forEach(item=>{
						if(item.business_type=='transfer'){
							this.goods_category.transfer.push(item);
						}else{
							this.goods_category.service.push(item)
						}
					})
					this.getGoodsList();
					this.getGoodsAttrDetail(this.all_category[0].attr_class_id);
				}
			})
		},
		changeShowStyle(){
			if(this.category_id){
				if(this.show_style == 3){
					this.show_style = 1;
				}else{
					this.show_style ++ ;
				}
			}else{
				if(this.show_style == 2){
					this.show_style = 1;
				}else{
					this.show_style ++ ;
				}
			}
		},
		chooseCategory(category_id,index,class_id){
			if(!category_id){
				this.show_style = 1;
			}
			this.goods_loading = true;
			this.current_category = index;
			this.category_id = category_id;
			this.attr_list = [];
			this.getGoodsAttrDetail(class_id);
			let lastSpot = this.$refs.scrollBox.scrollLeft;
			const nextSpace = 50; //每次移动距离
			let scrollItemTimer = setInterval(() => {
				this.$nextTick(() => {
					let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth;
					let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft;
					const containWidth = this.$refs.scrollBox.offsetWidth;
					if(this.$refs.scrollItem[0].offsetLeft == 0){
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2;
					}else{
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft;
					}
					if (Math.abs(lastSpot - resultSpot) < nextSpace) {
						this.getGoodsList();
						clearInterval(scrollItemTimer);
					}
					if (resultSpot >= lastSpot) {
						lastSpot = lastSpot + nextSpace;
					} else {
						lastSpot = lastSpot - nextSpace;
					}
					this.$refs.scrollBox.scrollTo(lastSpot, 0);
				});
			}, 15);
		},
		changeMode(mode){
			this.current_mode = mode;
			this.show_style = 1;
			this.goods_loading = true;
			this.getCategoryList();
			// this.getGoodsList();
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
	}
}
